import {useClipboardService} from "../services/clipboard";
import {useNotificationService} from "../services/notification";
import {useDebugLoggingService} from "../services/debugLogging";
import {useLocalizationService} from "../services/localization";
import {createVisuallyHidden, isHTMLElement} from "../utils";

const coderMessages = useLocalizationService({
    ru: {
        copyButtonLabel: "Скопировать код.",
        successMessage: "Код скопирован.",
        runButtonLabel: "Запустить в JDoodle.",
    },
    en: {
        copyButtonLabel: "Copy code snippet.",
        successMessage: "Code snippet is copied.",
        runButtonLabel: "Run in JDoodle.",
    },
});


export function initCoder(root: Document | HTMLElement) {
    root.querySelectorAll("[data-coder]").forEach((coder) => {
        const actionGroup = coder.querySelector(".active-block__action-group");
        if (actionGroup === null) {
            return;
        }

        initCopyButton(actionGroup);
        initRunButton(coder, actionGroup);
    })
}

function initCopyButton(actionGroup: Element) {
    const copyButton = document.createElement("button");
    copyButton.type = "button";
    copyButton.classList.add("active-block-action", "active-block-action--icon-copy");
    copyButton.append(createVisuallyHidden(coderMessages.get("copyButtonLabel")))
    copyButton.setAttribute("data-tooltip", "");

    actionGroup.append(copyButton);

    copyButton.addEventListener("click", async ({currentTarget}) => {
        if (!isHTMLElement(currentTarget)) {
            return;
        }

        try {
            const coderElement = currentTarget.closest("[data-coder]");
            if (coderElement === null) {
                return;
            }

            const copyTargets = [...coderElement.querySelectorAll("pre > code")];
            if (copyTargets.length === 0) {
                return;
            }

            const result = copyTargets
                .filter(isHTMLElement)
                .map((target) => getInnerText(target))
                .join("\n");

            await useClipboardService().writeContent(result);
            useNotificationService().notifyUser(coderMessages.get("successMessage"));
        } catch (e) {
            useDebugLoggingService()
                .warn(`Copier: clipboard service is denied.`);
            useDebugLoggingService()
                .error(e);
        }
    });

    function getInnerText(target: HTMLElement) {
        return [...target.childNodes]
            .filter((node) => !(isHTMLElement(node) && node.classList.contains("code-listing-comment")))
            .map((node) => node.textContent).join("")
    }
}

function initRunButton(coder: Element, actionGroup: Element) {
    const runValue = coder.getAttribute("data-coder-run");
    if (runValue === null) {
        return;
    }

    const runButton = document.createElement("a");
    runButton.href = runValue;
    runButton.classList.add("active-block-action", "active-block-action--icon-play");
    runButton.target = "_blank";
    runButton.rel = "nofollow";
    runButton.append(createVisuallyHidden(coderMessages.get("runButtonLabel")))
    runButton.setAttribute("data-tooltip", "");

    actionGroup.prepend(runButton);
}