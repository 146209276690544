import tippy from "tippy.js";
import {useLocalizationService} from "../services/localization";
import {useClipboardService} from "../services/clipboard";
import {useNotificationService} from "../services/notification";
import {createVisuallyHidden, isHTMLElement} from "../utils";

const linkerMessages = useLocalizationService({
    ru: {
        actionLabel: "Скопировать ссылку на этот раздел.",
        successMessage: "Ссылка скопирована.",
        canNotCopyLink: "Не получилось скопировать ссылку. Похоже, твой браузер не поддерживает эту функцию.",
    },
    en: {
        actionLabel: "Copy link to this section.",
        successMessage: "Link is copied.",
        canNotCopyLink: "Can not copy a link. Seems like your browser is not supported this function.",
    },
});

export function initLinker(root: Document | HTMLElement) {
    root.querySelectorAll("h1,h2,h3,h4,h5,h6").forEach((heading) => {
        if (heading.id === "") {
            return;
        }

        heading.classList.add("active-block");

        const actionGroup = document.createElement("div");
        actionGroup.classList.add("active-block__action-group", "active-block__action-group--position-center");

        const button = document.createElement("button");
        button.classList.add("active-block-action", "active-block-action--icon-link");
        button.setAttribute("type", "button");
        button.append(createVisuallyHidden(linkerMessages.get("actionLabel")));
        button.addEventListener("click", copyHeadingLink);

        tippy(button, {
            content: linkerMessages.get("actionLabel"),
        });

        actionGroup.append(button);

        heading.append(actionGroup);
    });
}

async function copyHeadingLink({currentTarget}: Event) {
    if (!isHTMLElement(currentTarget)) {
        return;
    }

    const heading = currentTarget.closest("h1,h2,h3,h4,h5,h6");
    if (heading === null || heading.id === "") {
        return;
    }

    try {
        const link = window.location.origin + window.location.pathname + "#" + heading.id;
        await useClipboardService().writeContent(link);
        useNotificationService().notifyUser(linkerMessages.get("successMessage"));
    } catch {
        useNotificationService().notifyUserAboutFailure(linkerMessages.get("canNotCopyLink"));
    }
}