import * as process from "process";

export type DebugLoggingService = {
    log<T>(content: T): void;
    warn<T>(content: T): void;
    error<T>(content: T): void;
};

const serviceSingleton = {
    log<T>(content: T): void {
        if (process.env.NODE_ENV !== "production") {
            console.log(content);
        }
    },
    warn<T>(content: T): void {
        if (process.env.NODE_ENV !== "production") {
            console.warn(content);
        }
    },
    error<T>(content: T): void {
        if (process.env.NODE_ENV !== "production") {
            console.error(content);
        }
    },
};

export function useDebugLoggingService(): DebugLoggingService {
    return serviceSingleton;
}