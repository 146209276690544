import IziToast, {IziToastSettings} from "izitoast";
import {createVisuallyHidden} from "../utils";
import {useLocalizationService} from "./localization";

export type NotificationService = {
    notifyUser(message: string): void;
    notifyUserAboutFailure(message: string): void;
}

const notificationInterfaceMessages = useLocalizationService({
    ru: {
        closeAlert: "Закрыть сообщение."
    },
    en: {
        closeAlert: "Close alert."
    },
});

function onOpening(_: IziToastSettings, toast: HTMLDivElement): void {
    toast.setAttribute("role", "alert");

    const closeButton = toast.querySelector(".iziToast-close");
    if (closeButton !== null) {
        closeButton.append(createVisuallyHidden(notificationInterfaceMessages.get("closeAlert")));
    }
};

export function useNotificationService(): NotificationService {
    return {
        notifyUser(message: string): void {
            IziToast.show({
                message,
                progressBar: false,
                onOpening,
            });
        },
        notifyUserAboutFailure(message: string): void {
            IziToast.error({
                message,
                progressBar: false,
                onOpening,
            });
        }
    };
}