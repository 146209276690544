export function isHTMLElement(target: object | null): target is HTMLElement {
    return target instanceof HTMLElement;
}

export function isSmallWidthLayout(): boolean {
    return window.matchMedia("(max-width: 600px)").matches;
}

export function whenReady(fn: () => void): void {
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

export function createVisuallyHidden(text: string): HTMLElement {
    const span = document.createElement("span");
    span.textContent = text;
    span.classList.add("visually-hidden");
    return span;
}