import {isHTMLElement} from "../utils";
import {useDebugLoggingService} from "../services/debugLogging";
import {useClipboardService} from "../services/clipboard";
import {useNotificationService} from "../services/notification";

export function initCopier(root: Document | HTMLElement): void {
    root.querySelectorAll("[data-copier-target-selector]").forEach((copyButton) => {
        copyButton.addEventListener("click", async ({target}) => {
            if (!isHTMLElement(target)) {
                useDebugLoggingService().warn("Copier: target is not HTMLElement.");
                return;
            }

            const targetSelector = target.getAttribute("data-copier-target-selector");

            if (targetSelector === null) {
                useDebugLoggingService().warn("Copier: targetSelector is null.");
                return;
            }

            const copyTarget = root.querySelector(targetSelector);

            if (copyTarget === null || !isHTMLElement(copyTarget)) {
                useDebugLoggingService()
                    .warn(`Copier: targetSelector is ${copyTarget === null ? "null" : "not HTMLElement"}.`);
                return;
            }

            try {
                await useClipboardService().writeContent(copyTarget.innerText);
                const successMessage = target.getAttribute("data-copier-success-message");
                if (successMessage !== null) {
                    useNotificationService().notifyUser(successMessage);
                }
            } catch (e) {
                useDebugLoggingService()
                    .warn(`Copier: clipboard service is denied.`);
                useDebugLoggingService()
                    .error(e);
            }
        })
    });
}