export type ClipboardService = {
    writeContent(content: string, type?: string): Promise<void>;
}

export function useClipboardService(): ClipboardService {
    return {
        async writeContent(content: string, type: string = "text/plain") {
            await writeContentUsingClipboardApi(content, type);
        }
    };
}

async function writeContentUsingClipboardApi(content: string, type: string) {
    try {
        const blob = new Blob([content], { type });
        await navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
    } catch {
        await navigator.clipboard.writeText(content);
    }
}