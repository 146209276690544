export type LocalizationService<T> = {
    get(key: keyof T): T[keyof T];
};

export type Messages<T> = {
    ru: Required<T>,
    en: Required<T>,
};

export type WebsiteLanguages = "ru" | "en";

export function getPageLanguage(): WebsiteLanguages {
    return document.documentElement.lang as WebsiteLanguages;
}

export function useLocalizationService<T>(messages: Messages<T>): LocalizationService<T> {
    return {
        get(key: keyof T): T[keyof T] {
            const language = getPageLanguage();
            return messages[language][key];
        },
    };
}