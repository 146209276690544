import tippy from "tippy.js";
import {isSmallWidthLayout, whenReady} from "./utils";
import {initLinker} from "./modules/linker";
import {initDetails} from "./modules/details";
import {initCopier} from "./modules/copier";
import {initCoder} from "./modules/coder";
import "@oddbird/popover-polyfill";

whenReady(() => {
    initToTopButton(document);
    initCoder(document);
    initTooltips(document);
    initCopier(document);
    initLinker(document);
    initDetails(document);
});

function initToTopButton(root: Document | HTMLElement): void {
    const backToTopButton = root.querySelector<HTMLElement>("#back-to-top");
    if (backToTopButton === null) {
        return;
    }

    document.addEventListener("scroll", () => {
        if (isSmallWidthLayout() || document.scrollingElement === null) {
            return;
        }

        if (document.scrollingElement.scrollTop > 100) {
            backToTopButton.removeAttribute("hidden");
        } else {
            backToTopButton.setAttribute("hidden", "hidden");
        }
    });
}

function initTooltips(root: Document | HTMLElement): void {
    root.querySelectorAll("[data-tooltip]").forEach((tooltipElement) => {
        let tooltipContent = tooltipElement.getAttribute("data-tooltip");

        if (tooltipContent === null || tooltipContent === "") {
            tooltipContent = tooltipElement.getAttribute("aria-label")
                ?? tooltipElement.textContent;
        }

        if (tooltipContent !== null) {
            tippy(tooltipElement, {
                content: tooltipContent,
            });
        }
    });
}